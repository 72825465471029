import {enrichOwnersWithRelatives, isCorporate} from '../common/casa-product-util';
import {Subscription} from 'rxjs/Subscription';
import _ from 'lodash';

import nxModule from 'nxModule';
import moment from 'moment';
import {pledgePaymentIntervals} from 'constants/deposit';

const templateUrl = require('./customer-term-deposits.template.html');
nxModule.component('customerTermDeposits', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $scope, $filter, command, customerCache, commandAccessChecker,
                        productDefinitionService, branchService, userCache, confirmation, notification,
                        $routeParams, modalPrintPreviewService, termDepositsService, transactionDetailsBuilder,
                        authentication, customerDepositService, userCounterService, casaTypesCache, fileService,
                        productLockCache, productLockService, revertCommandService, operationTableBuilder,
                        passbookService, certificateCache, queryParamsRemover, customerService,
                        http, reportModal) {

    const that = this;
    that.visibleDeposits = [];
    that.selectedDeposit = null;
    that.selectedAdvanceInterestLedger = null;
    that.selectedDepositTerm = null;
    that.depositTerms = [];
    that.item = null;
    that.selectedTransactionId = undefined;
    that.permissions = authentication.permissions;
    that.isValidationSlipActive = false;
    that.isPrintCertificateActive = false;
    that.showClosed = $routeParams.showClosed === "true";

    const customerId = $route.current.params['customerId'];
    that.depositId = $route.current.params['depositId'];

    queryParamsRemover.removeQueryOnRedirect($scope, ['showClosed', 'redirect']);

    commandAccessChecker.canExecuteCommandAsync()
      .then(canExecuteCommand => that.canExecuteCommand = canExecuteCommand);

    if (that.depositId) {
      passbookService.readByProductId(that.depositId).then(passbook => {
        that.activePassbook = passbook;
        that.activePassbook.nextLine = passbook.lastUsedLine + 1;
      });

      certificateCache.withParam(that.depositId).toPromise()
        .then(certificate => {
          that.activeCertificate = certificate
        });
    }

    modalPrintPreviewService.canReprint('TERM_DEPOSIT_VALIDATION_SLIP', (active) => {
      that.isValidationSlipActive = active;
    });
    modalPrintPreviewService.canReprint('CERTIFICATE_OF_TERM_DEPOSIT', (active) => {
      that.isPrintCertificateActive = active;
    });
    modalPrintPreviewService.canReprint('TERM_DEPOSIT_PASSBOOK', (active) => {
      that.isPassbookPrintActive = active;
    });

    that.showPrint = async (transaction) => {
      await modalPrintPreviewService.showAsync({
        printDescription: 'TERM_DEPOSIT_VALIDATION_SLIP',
        printProviderInput: {
          operationId: transaction.id
        }
      });
    };

    that.isValidationSlipSupported = (transaction) => {
      return ['WITHDRAW_CASH',
        'WITHDRAW_CHECK',
        'DEPOSIT_CASH',
        'DEPOSIT_CUTOFF_CHECK',
        'DEPOSIT_CHECK',
        'CREDIT_MEMO',
        'DEBIT_MEMO',
        'TRANSFER_FUNDS'].includes(transaction.operationGroup);
    };

    that.toggleClosedDeposits = () => {
      if (that.selectedDeposit) {
        $location
          .path(`/customer/${customerId}/term-deposits/${that.selectedDeposit.id}`)
          .search('showClosed', that.showClosed.toString());
      }
    };

    that.toggleAdvanceInterestLedger = async (deposit) => {
      if(deposit.depositSubgroup === 'ADVANCE_INTEREST' && that.selectedDepositTerm) {
        const [ledger, terms] = await Promise.all([
          http.get(`/products/deposits/advance-interest-ledger/${that.selectedDepositTerm}`).toPromise(),
          http.get(`/products/deposits/${deposit.id}/term`).toPromise()
        ]);
        that.selectedAdvanceInterestLedger = ledger;
        that.depositTerms = terms;
      } else {
        that.selectedAdvanceInterestLedger = null;
      }
    };

    that.selectAdvanceInterestLedger = async () => {
      const ledger = await http.get(`/products/deposits/advance-interest-ledger/${that.selectedDepositTerm}`).toPromise();
      that.selectedAdvanceInterestLedger = ledger;
    };

    that.keyValueDetails = _.memoize(currentTransaction => {
      let baseValueDetails = transactionDetailsBuilder.build(currentTransaction, 'DEPOSIT');
      baseValueDetails.push(transactionDetailsBuilder.buildCurrencyIfNotZero('Available Balance after', currentTransaction.balanceAfter));

      baseValueDetails = transactionDetailsBuilder.clean(baseValueDetails);

      return baseValueDetails;
    });

    that.keyValueDetails.cache = new WeakMap();

    const confirmationIfPassbookRequired = (action) => {
      if (that.selectedDeposit.passbookConfig === 'PASSBOOK_REQUIRED') {
        confirmation('Is passbook available?', action);
      } else {
        action();
      }
    };

    that.onlyClosedDeposits = () => {
      return that.depositAccounts && that.depositAccounts.filter(deposit => deposit.status !== 'CLOSED').length === 0;
    };

    // Due to read-only purpose, read account in all statuses
    // and always fetch accounts of related people
    that.setRelatedAccountNames = async (deposits) => {
      const allRelatedAccountIds = deposits
        .map(d => d.relatedAccountId)
        .filter(acc => acc);

      const uniqueAccountIds = [...new Set(allRelatedAccountIds)];
      if (uniqueAccountIds.length === 0) {
        return;
      }

      const relatedAccounts = await http.get('/products/accounts', {
        params: {
          ids: uniqueAccountIds,
          includeOwnership: true
        }
      }).toPromise();

      for (const d of deposits) {
        if (d.relatedAccountId) {
          const account = _.find(relatedAccounts, {id: d.relatedAccountId});
          if (account) {
            const owner = _.find(account.ownership, {ownershipType: 'OWNER'});
            d.relatedAccountName = `${owner.effectiveName} ${account.productNumber}`;
          } else {
            d.relatedAccountName = '<Unknown>';
          }
        }
      }
    };

    that.selectDeposit = (deposit) => {
      $location.path(`/customer/${customerId}/term-deposits/${deposit.id}`)
    };

    that.prepareDepositButtonLinks = (deposit) => {
      that.cashPlacement = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/cash-placement`);
      that.checkPlacement = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/check-placement`);
      that.transferPlacement = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/transfer-placement`);
      that.memoPlacement = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/placement-credit-memo`);
      that.interestsCashWithdrawal = () => confirmationIfPassbookRequired(() =>
        $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/interests-cash-withdrawal`));
      that.interestsCheckWithdrawal = () => confirmationIfPassbookRequired(() =>
        $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/interests-check-withdrawal`));
      that.interestsTransferToProduct = () => confirmationIfPassbookRequired(() =>
        $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/interests-transfer-to-product`));
      that.creditMemo = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/credit-memo`);
      that.debitMemo = () => confirmationIfPassbookRequired(() =>
        $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/debit-memo`));
      that.createHold = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/create-hold`);
      that.createLock = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/create-lock`);
      that.terminateWithCash = () => confirmationIfPassbookRequired(() =>
        $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/cash-termination`));
      that.terminateWithCheck = () => confirmationIfPassbookRequired(() =>
        $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/check-termination`));
      that.terminateWithMemo = () => confirmationIfPassbookRequired(() =>
        $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/memo-termination`));
      that.rollover = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/manual-rollover`);
      that.close = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/close`);
      that.edit = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/edit`);
      that.editSignatureCard = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/edit-signature-card`);
      that.partialWithdrawalCash = () => confirmationIfPassbookRequired(() =>
        $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/partial-withdrawal-cash`));
      that.partialWithdrawalCheck = () => confirmationIfPassbookRequired(() =>
        $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/partial-withdrawal-check`));
      that.transferToProduct = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/transfer-to-product`);
      that.issuePassbook = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/issue-passbook`);
      that.issueCertificate = () => $location.path(`/customer/${customerId}/term-deposits/${deposit.id}/issue-certificate`);
    };


    const s = customerCache.profile(customerId, true, authentication.permissions['CST_CLOSED_CIF_READ']).toObservable()
      .combineLatest(customerCache.termDeposits(customerId, false).toObservable(), (profile, deposits) => {

        customerService.redirectWhenProfileIsInvalid(profile);
        if (profile.status === 'CLOSED') {
          return [];
        }

        that.profile = profile;
        return deposits;
      })
    // enrich with productName from product definitions
      .combineLatest(productDefinitionService.toObservable(), (deposits, products) =>
        deposits.map(da => {
          const p = _.find(products, {
            id: da.definitionId
          });
          return Object.assign(da, {
            depositType: p,
            productName: p ? p.productName : '<Unknown>'
          });
        })
      )
      // enrich with passbook configuration
      .combineLatest(termDepositsService.toObservable(), (deposits, termDepositTypes) =>
        deposits.map(deposit => {
          const depositType = termDepositTypes.find(dt => dt.id === deposit.typeId);
          return {...deposit,
                  passbookConfig: depositType ? depositType.passbookConfig : null,
                  printPassbookAfterValidationSlip: depositType ? depositType.printPassbookAfterValidationSlip : false
          };
        })
      )
      // enrich with branch name from branch service
      .combineLatest(branchService.toObservable(), (deposits, branches) =>
        deposits.map(da => {
          that.depositBranch = _.find(branches, {id: da.branchId});
          return Object.assign(da, {branchName: that.depositBranch.name});
        })
      )
      // enrich with user name from user service
      .combineLatest(userCache.withParam().toObservable(), (deposits, users) =>
        deposits.map(da => {
          const user = _.find(users, {
            id: da.userId
          });
          return Object.assign(da, {
            createdBy: user ? user.effectiveName : '<Unknown>'
          });
        })
      )
      // enrich with productName from product definitions
      .combineLatest(termDepositsService.toObservable(), (deposits, types) =>
        deposits.map(da => {
          const type = _.find(types, (d) => Number(d.id) === Number(da.typeId));
          return Object.assign(da, {
            type: type,
            depositGroup: type ? type.depositGroup : '<Unknown>'
          });
        })
      )
      // enrich with pdic casa types
      .combineLatest(casaTypesCache.toObservable(), (deposits, casaTypes) => {
        that.pdicCasaTypes = _.filter(casaTypes, {'regulatorType': 'PDIC'});
        return deposits.map(da => {
          const name = _.find(that.pdicCasaTypes, { id: da.pdicTypeId }).name;
          return Object.assign(da, {
            pdicTypeName: name.substring(name.indexOf('/') + 1)
          });
        })
      })
      .subscribe(async deposits => {
        that.depositAccounts = deposits;
        that.visibleDeposits = deposits.filter(d => that.showClosed || d.status !== 'CLOSED');

        const deposit = (that.depositId && _.find(that.depositAccounts, d => String(d.id) === that.depositId));

        if (!that.depositId && deposits.length > 0) {
          that.selectDeposit(deposits[0]);
        } else if (!that.showClosed && deposit?.status === 'CLOSED' && that.visibleDeposits[0]) {
          that.selectDeposit(that.visibleDeposits[0]);
        } else if (!that.showClosed && deposit?.status === 'CLOSED' && that.onlyClosedDeposits()) {
          that.showClosed = true;
          that.selectedDeposit = deposit;
          that.selectedDepositTerm = deposit.termId;
          await that.toggleAdvanceInterestLedger(deposit);
          that.toggleClosedDeposits();
        } else {
          that.selectedDeposit = deposit;
          that.selectedDepositTerm = deposit.termId;
          await that.toggleAdvanceInterestLedger(deposit);

          // Any path change will eventually end up here - now we can execute specific logic just once
          await that.setRelatedAccountNames(deposits)
        }

        if (deposit) {
          that.prepareDepositButtonLinks(deposit);

          that.isCorporate = isCorporate(that.profile, deposit, that.pdicCasaTypes);
          if (that.isCorporate) {
            enrichOwnersWithRelatives(deposit, that.profile.relatedCustomers);
          }
        } else if (that.depositId) {
          $location.path(`/customer/${customerId}/term-deposits`);
        }

        return deposits;
      });

    const subscription = new Subscription();
    subscription.add(s);

    that.switchContext = (owner) => {
      let redirectionUrl = `/customer/${owner.customerId}/accounts`;
      $location.path(redirectionUrl);
    };

    if (that.depositId) {
      // Add product locks subscription
      subscription.add(productLockCache.withParam(that.depositId).toObservable().subscribe(locks => {
        that.locks = locks;
        that.nonReleasedLocks = _.filter(locks || [], {released: false});
      }));
    }

    that.depositIsLocked = () => !_.isEmpty(that.nonReleasedLocks);

    that.transactionClicked = (transaction, $event) => {
      $event.stopPropagation();
      that.selectedTransactionId = transaction.id;
    };

    that.hideInlinePanel = () => {
      that.selectedTransactionId = null;
    };

    that.createNew = () => $location.path(`/customer/${customerId}/term-deposits/create`);

    that.balanceIsPositive = () => that.selectedDeposit && that.selectedDeposit.balance > 0;

    that.creditMemoAllowed = () => {
      return ['PENDING', 'ACTIVE', 'MATURE', 'INACTIVE'].includes(that.selectedDeposit.status)
        && !that.depositIsLocked();
    };

    that.editAvailable = () => that.selectedDeposit.status !== 'CLOSED'
      && !that.depositIsLocked();

    that.placementAllowed = () => {
      let deposit = that.selectedDeposit;
      if (that.depositIsLocked()) return false;
      if ('PENDING' === deposit.status) return true;
      if (['ACTIVE', 'MATURE', 'INACTIVE'].includes(deposit.status)) {
        switch (deposit.type.placementStrategy) {
          case 'ADHOC':
            return true;
          case 'AFTER_CREDITING':
            const scheduler = deposit.creditingScheduler;
            if (scheduler && scheduler.enabled && scheduler.lastExecution) {
              const systemDate = moment(that.depositBranch.systemDate);
              const lastCrediting = moment(scheduler.lastExecution);
              return systemDate >= lastCrediting;
            }
            return false;
          default:
            return false;
        }
      }
      return false;
    };

    const schedulerExecutionAllowed = deposit => {

      const scheduler = deposit.creditingScheduler;
      if (!scheduler || !scheduler.enabled || !scheduler.cycleType || !scheduler.interval) return false;

      const referenceDate = scheduler.lastExecution || deposit.activatedOn;
      if (!referenceDate) return false;

      const interval = scheduler.interval;
      const start = moment(referenceDate);
      const systemDate = moment(that.depositBranch.systemDate);

      switch (scheduler.cycleType) {
        case 'DAY':
          return systemDate >= start.add(interval, 'days');
        case 'WEEK':
          return systemDate >= start.add(interval * 7, 'days');
        case 'MONTH':
          return systemDate >= start.add(interval, 'months');
        case 'MONTH_END':
          return systemDate >= start.startOf('month').add(interval, 'months').subtract(1, 'days');
        case 'QUARTER':
          return systemDate >= start.add(interval * 3, 'months');
        case 'QUARTER_END':
          return systemDate >= start.startOf('quarter').add(interval * 3, 'months').subtract(1, 'days');
        case 'YEAR':
          return systemDate >= start.add(interval, 'years');
        default:
          return false;
      }
    };

    const interestWithdrawalAllowed = deposit =>
      ['ACTIVE', 'MATURE', 'INACTIVE'].includes(deposit.status)
      && deposit.activatedOn
      && (deposit.depositSubgroup === 'ADVANCE_INTEREST' || schedulerExecutionAllowed(deposit));

    that.anyWithdrawalAllowed = () =>
      (that.cashWithdrawalAllowed() || that.checkWithdrawalAllowed() || that.transferToProductAllowed())
        && !that.depositIsLocked();

    that.cashWithdrawalAllowed = () => {
      const deposit = that.selectedDeposit;
      return ['MATURE', 'INACTIVE'].includes(deposit.status)
        || (['CASH_WITHDRAWAL', 'ADHOC_WITHDRAWAL'].includes(deposit.creditingStrategy) || deposit.depositSubgroup === 'ADVANCE_INTEREST')
        && interestWithdrawalAllowed(deposit) && that.canExecuteCommand('WithdrawDepositInterestByCash');
    };

    that.checkWithdrawalAllowed = () => {
      const deposit = that.selectedDeposit;
      return ['MATURE', 'INACTIVE'].includes(deposit.status)
        || (['CHECK_WITHDRAWAL', 'ADHOC_WITHDRAWAL'].includes(deposit.creditingStrategy) || deposit.depositSubgroup === 'ADVANCE_INTEREST')
        && interestWithdrawalAllowed(deposit) && that.canExecuteCommand('WithdrawDepositInterestByCheck');
    };

    that.transferToProductAllowed = () => {
      const deposit = that.selectedDeposit;
      return ['MATURE', 'INACTIVE'].includes(deposit.status)
          || (['PRODUCT_TRANSFER'].includes(deposit.creditingStrategy) || deposit.depositSubgroup === 'ADVANCE_INTEREST')
          && interestWithdrawalAllowed(deposit)  && that.canExecuteCommand('InterestsTransferToProduct');
    };

    /**
     * Debit memo operation can be performed when:
     * 1. Product balance is > 0
     * 2. Product is: PENDING/ACTIVE/MATURE/INACTIVE
     * 3. Product is not locked
     */
    that.debitMemoAllowed = () => that.balanceIsPositive() && that.creditMemoAllowed() && that.selectedDeposit.depositSubgroup !== 'ADVANCE_INTEREST';

    /**
     * Create hold operation can be performed when:
     * 1. Product balance is > 0
     * 2. Product is: ACTIVE/MATURE/INACTIVE
     */
    that.createHoldAvailable = () => that.balanceIsPositive()
      && ['ACTIVE', 'MATURE', 'INACTIVE'].includes(that.selectedDeposit.status)
      && !that.depositIsLocked();

    /**
     * Deposit termination can be performed when:
     * 1. Product available balance is > 0
     * 2. Product is: ACTIVE/MATURE/INACTIVE
     */
    that.terminationAllowed = () => that.balanceIsPositive()
      && ['ACTIVE', 'MATURE', 'INACTIVE'].includes(that.selectedDeposit.status)
      && !that.depositIsLocked();

    /**
     * Deposit rollover can be performed when:
     * 1. Maturity strategy = NONE
     * 2. Product is MATURE/INACTIVE
     */
    that.rolloverAllowed = () => that.selectedDeposit.maturityStrategy === 'NONE'
      && ['MATURE', 'INACTIVE'].includes(that.selectedDeposit.status)
      && !that.depositIsLocked();

    /**
     * Deposit closing can be performed when:
     * 1. Product total balance is 0
     * 2. Product is PENDING/MATURE
     */
    that.closingAllowed = () => {
      let deposit = that.selectedDeposit;
      return deposit.status !== 'CLOSED' &&
        deposit.balance === 0
        && deposit.holdsBalance === 0
        && !that.depositIsLocked();
    };

    that.editAvailable = () => that.selectedDeposit.status !== 'CLOSED' && !that.depositIsLocked();

    that.createLockAvailable = () => !that.depositIsLocked() && that.selectedDeposit.status !== 'CLOSED';

    that.releaseLockAvailable = () => that.depositIsLocked() && that.selectedDeposit.status !== 'CLOSED';

    that.releaseLock = () => {
      productLockService.releaseProductLocks(that.selectedDeposit, null, () => {
        customerCache.depositAccounts(customerId).refetch();
        $route.reload();
      })
    };

    that.partialWithdrawalAllowed = () => {
      return that.selectedDeposit
        && that.selectedDeposit.balance > 0
        && that.selectedDeposit.type.partialWithdrawalAllowed
        && _.includes(['ACTIVE', 'MATURE', 'INACTIVE'], that.selectedDeposit.status)
        && !that.depositIsLocked();
    };

    that.revertAllowed = operation => revertCommandService.revertAllowed(operation);

    const revert = (operation, input, question, revertCommandName) => {
      confirmation(question, () =>
        command.execute(revertCommandName, input).success(() => {
          clearCaches();
        }));
    };

    const clearCaches = () => {
      customerCache.termDeposits(customerId).refetch();
      customerCache.depositAccounts(customerId).refetch();
      userCounterService.refresh();
    }

    that.revertCashPlacement = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the cash placement of ${$filter('nxCurrency')(operation.amount)}?`,
      'DepositCashPlacementRevert'
    );

    that.revertCheckPlacement = async (operation) => {
      const confirmed = await confirmation(`Do you want to revert the check placement of ${$filter('nxCurrency')(operation.amount)}?`);
      if (!confirmed) {
        return;
      }

      let commandId = operation.commandId;
      const revertCommandName = operation.operationSubgroup === 'CREDIT_CHECK_ON_US' ? 'DepositCheckPlacementByOnUsCheckRevert' : 'DepositCheckPlacementRevert';
      if(operation.operationSubgroup === 'CREDIT_CHECK_ON_US') {
        //Deposit on us checks call CreditOnUsCheck internally, hence the command id linked to this operation is the child ID.
        //We need to supply the root command id
        const descriptor = await command.getCommandDescriptorById(commandId);
        commandId = descriptor.rootCommandId;
      }
      await command.execute(revertCommandName, {
        operationId: operation.id,
        commandId: commandId
      }).toPromise();
      clearCaches();
    }

    that.revertCreditMemo = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the credit memo for amount of ${$filter('nxCurrency')(operation.amount)}?`,
      operation.operationSubgroup === 'TERM_DEPOSIT_PLACEMENT' ? 'DepositMemoPlacementRevert' : 'TermDepositCreditMemoRevert'
    );

    that.revertDebitMemo = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the debit memo for amount of ${$filter('nxCurrency')(operation.amount)}?`,
      'TermDepositDebitMemoRevert'
    );

    that.returnCheck = (operation) => {
      const input = {'id': operation.checkId};
      command.execute('ReturnDepositCheck', input).success(() => {
        customerCache.termDeposits(customerId).refetch();
      });
    };

    that.revertInternalTransfer = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the transfer placement of ${$filter('nxCurrency')(operation.amount)}?`,
      'DepositTransferPlacementRevert'
    );

    that.passbookIssueAvailable = () => passbookSupported(that.selectedDeposit)
      && ['ACTIVE', 'MATURE', 'INACTIVE'].includes(that.selectedDeposit.status)
      && !that.depositIsLocked();

    that.certificateIssueAvailable = () => !passbookSupported(that.selectedDeposit)
      && that.isPrintCertificateActive
      && ['ACTIVE', 'MATURE', 'INACTIVE'].includes(that.selectedDeposit.status)
      && !that.depositIsLocked();

    const passbookSupported = (depositType) => depositType ? depositType.passbookConfig !== 'PASSBOOK_NOT_SUPPORTED' : false;

    that.hasActivePassbook = () => passbookSupported(that.selectedDeposit) && that.activePassbook;

    that.hasActiveCertificate = () => !passbookSupported(that.selectedDeposit) && that.activeCertificate;

    that.reprintCertificate = async () => {
      await modalPrintPreviewService.showAsync({
        printDescription: {
          code: 'CERTIFICATE_OF_TERM_DEPOSIT',
          parameters: {
            'PRODUCT_DEFINITION' : that.selectedDeposit.definitionId
          }
        },
        printProviderInput: {
          depositId: that.selectedDeposit.id
        }
      });
    };

    that.hasReturnCheckOperation = operation => {
      return 'RETURN_CHECK_OPERATION_ID' in operation.attributes;
    };

    that.formatProductAndExtraNumber = (item) =>
      (item.extraNumber === item.productNumber || !item.extraNumber) ? item.productNumber :
        `${item.productNumber} (${item.extraNumber})`;

    that.$onDestroy = () => {
      subscription.unsubscribe();
    };

    that.getOwnership = _.memoize((ownership, isCorporate) => {
      if (!ownership) {
        return [];
      }

      if (!isCorporate) {
        return ownership;
      }

      return ownership.filter(ownership => ownership.ownershipType !== 'OWNER');
    });

    that.printPledgeInstallmentSchedule = () => {
      const reportCode = "PledgeInstallmentScheduleReport";
      reportModal.display({
        params: {
          depositId: that.selectedDeposit.id,
          branchId: that.depositBranch.id
        },
        reportCode: reportCode
      });
    }

    that.getPledgePaymentIntervalLabel = (interval) => {
      return pledgePaymentIntervals.find(i => i.value === interval)?.label ?? interval;
    }

    that.isComputationSheetAvailable = (item) => {
      const isPlacementAllowedAnytime = that.selectedDeposit.type.placementStrategy === 'ADHOC';
      return !isPlacementAllowedAnytime && ['CashDepositPretermination', 'CheckDepositPretermination', 'MemoDepositPretermination',
        'InterestsTransferToProduct', 'WithdrawDepositInterestByCash', 'WithdrawDepositInterestByCheck',
        'DepositPartialWithdrawalCash', 'DepositPartialWithdrawalCheck', 'DepositWithdrawalTransferToProduct'
      ].includes(item.commandTypeName) && item.operationGroup === 'APPLY_INTEREST';
    }

    that.printComputationSheet = (item) => {
      reportModal.display({
        params: {
          commandId: item.commandId
        },
        reportCode: 'ComputationSheetReport'
      });
    }
  }
});
