import {
  NxFormik,
  NxFormikButton,
  NxFormikInput,
  NxFormikSelect,
  NxLoader,
  NxRow,
  NxRowPosition,
  NxSelect,
  NxSelectOption,
  NxStack
} from '@nextbank/ui-components';
import {useCommand} from 'command/CommandService';
import {CommandOutputWrapper} from 'command/CommandTypes';
import {MisGroup} from 'components/service/loan.types';
import CustomFieldCategoryTreeView, {CustomFieldCategoryValue} from 'custom-field/CustomFieldCategoryTreeView';
import {
  categoryGroupSelect,
  CustomFieldDefinition,
  CustomFieldGroup,
  CustomFieldRestriction,
  RestrictedFieldType
} from 'custom-field/CustomFieldDefinitionTypes';
import customFieldService, {useDefinitionRestrictions} from 'custom-field/CustomFieldService';
import NxForm from 'form/NxForm';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import {FormikProps} from 'formik';
import misGroupService from 'loan/MisGroupService';
import NxCancelButton from 'NxCancelButton';
import React, {ReactElement, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {NxTreeNode} from 'tree/NxTreeNode';
import NxFormikSingleSelectTree, {SingleSelectCategoryValue} from 'tree/singleSelectTree/NxFormikSingleSelectTree';
import * as Yup from 'yup';

interface FormInput {
  customFieldCategoryId: number;
  restrictedFieldId: number;
  restrictedFieldType: string;
  restrictedFieldGroup?: string | null;
  restrictedFieldDefaultValue?: string | null;
  restrictedFieldDefaultCategoryId?: number | null;
}

const restrictedTypeOption : NxSelectOption<RestrictedFieldType>[] = [
  {
    label: 'Custom field',
    value: 'CUSTOM'
  },
  {
    label: 'Mis group',
    value: 'MIS'
  }
]

export interface MisCategoryValue extends SingleSelectCategoryValue{
  code: string;
  parentCode?: string;
}

const CustomFieldRestrictionEdit = ({group}: {group: CustomFieldGroup}): ReactElement => {
  const execute = useCommand();
  const history = useHistory();
  const {id, restrictionId} = useParams<{id: string, restrictionId: string}>();
  const isNew = restrictionId === 'new';
  const [{data: response}] = useDefinitionRestrictions({id: Number(restrictionId)});
  const [restriction, setRestriction] = useState<CustomFieldRestriction | null>(null);
  const [restrictedFieldGroup, setRestrictedFieldGroup] = useState<string>("CUSTOMER");
  const [definitions, setDefinitions] = useState<CustomFieldDefinition[]>([]);
  const [nodesByDefinitionId, setNodesByDefinitionId] = useState<Record<number, NxTreeNode<CustomFieldCategoryValue>[]>>([]);
  const [definitionsById, setDefinitionById] = useState<Map<number, CustomFieldDefinition>>();
  const [misNodes, setMisNodes] = useState<NxTreeNode<MisCategoryValue>[]>([]);

  const prepareData = async (response: CustomFieldRestriction[] | undefined): Promise<void> => {
    const [categories, allDefinitions, misGroups] = await Promise.all([
      customFieldService.readCategories(),
      customFieldService.readDefinitions({enabled: true}),
      misGroupService.readGroups()
    ]);

    const allNodes = categories.filter(c => c.enabled)
      .map(c => customFieldService.mapCategoryToNode(c, allDefinitions));
    const nodesByDefinitionId: Record<number, NxTreeNode<CustomFieldCategoryValue>[]> = [];
    for (const node of allNodes) {
      if (node.value.definitionId) {
        if (!nodesByDefinitionId[node.value.definitionId]) {
          nodesByDefinitionId[node.value.definitionId] = [node];
        } else {
          nodesByDefinitionId[node.value.definitionId].push(node);
        }
      }
    }
    setNodesByDefinitionId(nodesByDefinitionId);
    setDefinitions(allDefinitions);
    const definitionsMap = new Map(allDefinitions.map(i => [i.id, i]));
    setDefinitionById(definitionsMap);

    const idMap: Record<number, MisGroup> = {}
    const root: MisGroup[] = [];
    misGroupService.buildHierarchy(misGroups, idMap, root);
    const misNodes = root.map(c => misGroupService.mapGroupToNode(c));
    setMisNodes(misNodes);

    if (!response || response.length !== 1) {
      return;
    }
    const restrictedField = allDefinitions.find(d => d.id === response[0].restrictedFieldId)
    if (restrictedField) {
      setRestrictedFieldGroup(restrictedField.group);
    }
    setRestriction(response[0]);
  }

  useEffect(() => {prepareData(response);}, [response]);

  if(!isNew && !restriction) {
    return <NxLoader/>;
  }

  const FormSchema: Yup.SchemaOf<FormInput> = Yup.object().shape({
    customFieldCategoryId: Yup.number()
      .required()
      .label('Category is required'),
    restrictedFieldId: Yup.number()
      .required()
      .label('Restricted field is required'),
    restrictedFieldGroup: Yup.string().nullable(),
    restrictedFieldType: Yup.string()
      .required()
      .label('Restricted field type is required'),
    restrictedFieldDefaultValue: Yup.string()
      .when('restrictedFieldType', {
        is: 'CUSTOM',
        then: Yup.string().nullable()
      }),
    restrictedFieldDefaultCategoryId: Yup.number().nullable()
  });
  return (
    <NxPage>
      <NxHeader>
        {isNew ? 'New restriction' : `Edit restriction`}
      </NxHeader>
      <NxFormik<Partial<FormInput>>
        initialValues={{
          customFieldCategoryId: restriction?.customFieldCategoryId,
          restrictedFieldType: restriction?.restrictedFieldType,
          restrictedFieldGroup: restrictedFieldGroup,
          restrictedFieldId: restriction?.restrictedFieldId,
          restrictedFieldDefaultValue: restriction?.restrictedFieldDefault?.value,
          restrictedFieldDefaultCategoryId: restriction?.restrictedFieldDefault?.categoryId
        }}
        validationSchema={FormSchema}
        validateOnMount={true}
        onSubmit={async (input: FormInput): Promise<void> => {
          const response: CommandOutputWrapper<void> = await execute<CustomFieldRestriction, void>({
            name: 'UpsertCustomFieldRestriction',
            input: {
              id: restrictionId ? Number(restrictionId) : null,
              customFieldId: Number(id),
              customFieldCategoryId: input.customFieldCategoryId,
              restrictedFieldType: input.restrictedFieldType as RestrictedFieldType,
              restrictedFieldId: input.restrictedFieldId,
              restrictedFieldDefault: input.restrictedFieldType === 'MIS'? null : {
                categoryId: input.restrictedFieldDefaultCategoryId,
                value: input.restrictedFieldDefaultValue
              }
            }
          })
          if (!response.approvalRequired) {
            history.goBack();
          }
        }}>
        {({isValid, values, submitForm, isSubmitting, setFieldValue}: FormikProps<FormInput>): ReactElement => {
          return <NxForm>
            <NxStack>
              <NxFormikSingleSelectTree
                label="Category"
                name="customFieldCategoryId"
                nodes={nodesByDefinitionId[Number(id)]}
                TreeNodeView={CustomFieldCategoryTreeView}/>
              <NxSelect<string>
                label="Restricted field type"
                options={restrictedTypeOption}
                value={values.restrictedFieldType}
                onChange={(value: string): void => {
                  setFieldValue('restrictedFieldType', value, true);
                  setFieldValue('restrictedFieldId', undefined, true);
                  setFieldValue('restrictedFieldDefaultValue', undefined, true);
                  setFieldValue('restrictedFieldDefaultCategoryId', undefined, true);
                }}
                error={(!values.restrictedFieldType) ? 'Restricted field type is required' : undefined}
              />
              {
                values.restrictedFieldType ?
                  (
                    values.restrictedFieldType === 'CUSTOM' ?
                      (
                        <>
                          <NxFormikSelect<string> label="Restricted field group" options={categoryGroupSelect.filter(c => {
                            if(group === 'CUSTOMER') return true;
                            return [group, 'CUSTOMER'].includes(c.value);
                          })}
                                                  name="restrictedFieldGroup"/>
                          <NxFormikSelect<number> label="Restricted field"
                                                  options={definitions.filter(d => d.group === values.restrictedFieldGroup && d.id !== Number(
                                                    id)).map(d => (
                                                    {
                                                      label: d.name,
                                                      value: d.id
                                                    }))}
                                                  name="restrictedFieldId"/>
                          {
                            (values.restrictedFieldId && definitionsById?.get(values.restrictedFieldId)) ?
                              definitionsById?.get(values.restrictedFieldId)?.type === 'TEXT' ?
                                <NxFormikInput label="Default value" name="restrictedFieldDefaultValue"/>
                                : (
                                  <>
                                    <NxFormikSingleSelectTree
                                      label="Default category"
                                      name="restrictedFieldDefaultCategoryId"
                                      nodes={values.restrictedFieldId
                                        ? nodesByDefinitionId[values.restrictedFieldId]
                                        : []}
                                      TreeNodeView={CustomFieldCategoryTreeView}/>
                                  </>
                                ) :
                              <div/>
                          }
                        </>
                      ) :
                      (
                        <>
                          <NxFormikSingleSelectTree
                            label="Mis group"
                            name="restrictedFieldId"
                            nodes={misNodes}
                            TreeNodeView={CustomFieldCategoryTreeView}/>
                        </>
                      )
                  ):
                  <></>
              }

              <NxRow position={NxRowPosition.END}>
                <NxCancelButton/>
                <NxFormikButton
                  disabled={!isValid}
                  loaded={!isSubmitting}
                  onClick={submitForm}
                  type="submit">
                  Confirm
                </NxFormikButton>
              </NxRow>
            </NxStack>
          </NxForm>
        }}
      </NxFormik>
    </NxPage>
  )
}

export default CustomFieldRestrictionEdit;